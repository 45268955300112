<template>
    <div class="header-wrapper">
        <div class="header">
            <router-link to="/" class="logo">
                <img src="../../assets/images/yiyatong_logo.png" alt="">
            </router-link>
            <el-menu :default-active="activeIndex" class="nav" mode="horizontal" @select="selectRouter" v-if="!islogin">
                <el-menu-item index="qualityCourse" v-if="role!=3">
<!--                    <router-link class="nav-item" :to="{name: 'qualityCourse'}">课程</router-link>-->
                    <router-link class="nav-item" to="/qualityCourse/courseindex">课程</router-link>
                </el-menu-item>
                <el-menu-item index="qualityCourse" v-if="role===3">
                    <router-link class="nav-item" :to="{name: 'teacherCourseIndex'}">课程</router-link>
                </el-menu-item>
                <el-menu-item index="classRecord" v-if="role === 3">
                    <router-link class="nav-item" to="/qualityCourse/classRecord">上课记录</router-link>
                </el-menu-item>
                <el-menu-item index="qualityEducational"  v-if="role === 3">
                    <router-link class="nav-item" to="/qualityCourse/educational">教务管理</router-link>
                </el-menu-item>
                <!--<el-menu-item index="training">-->
					<!--<a :href="softUrl" target="_blank" class="nav-item">实训系统</a>-->
                <!--</el-menu-item>-->
                <el-menu-item index="myClass" v-if="role === 4">
                    <router-link class="nav-item" to="/myclass/detail">我的班级</router-link>
                </el-menu-item>
				<el-menu-item index="myClass"  v-if="role === 3">
				    <router-link class="nav-item" to="/myClass">班级管理</router-link>
				</el-menu-item>
                <el-menu-item index="qualityCoursePublicClass">
                    <router-link class="nav-item" to="/qualityCourse/PublicClass">公开课</router-link>
                </el-menu-item>
                <el-menu-item index="qualityCourseProjectClassify">
                    <router-link class="nav-item" to="/qualityCourse/projectclassify">项目库</router-link>
                </el-menu-item>
                <!--<el-menu-item index="qualityCourseNewsCenter" v-if="role === 3">-->
                    <!--<router-link class="nav-item" to="/qualityCourse/newsCenter">新闻中心</router-link>-->
                <!--</el-menu-item>-->
            </el-menu>
            <div class="user" v-if="!islogin">
                <div class="m-userinfo">
                    <router-link :to="{path: '/user/login', query: {r: 't'}}" v-if="!isLogin">教师登录</router-link>
                </div>
                <div class="user-info">
                    <div class="user-avatar">
                        <img :src="userIcon ? userIcon : require('../../assets/images/user_img.png')" alt="" />
                    </div>
                    <el-dropdown class="header-el-dropdown" v-if="isLogin" @command="logout">
                    <span class="user-text">
                            <span class="user-name text-overflow">{{username}}</span>
                            <i class="el-icon-arrow-down"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item icon="el-icon-switch-button" command="1">退出</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HeaderModule",
        data() {
            return {
                activeIndex: '',
                isLogin: false,
				role: '',
                username: '',
                userIcon: '',
                softUrl: localStorage.getItem('softUrl') ? localStorage.getItem('softUrl') : 'http://shixun.jdekt.com/'
            }
        },
        props: ['islogin'],
        created() {
            if (this.$route.name.indexOf('teacherCourseIndex') !== -1) {
                this.activeIndex = 'qualityCourse';
            } else if (this.$route.name.indexOf('studentCourseIndex') !== -1) {
                this.activeIndex = 'qualityCourse';
            } else {
                this.activeIndex = this.$route.name;
            }
            
            if (localStorage.getItem('sUsername')) {
                this.isLogin = true;
            } else {
                this.isLogin = false;
            }
			this.watchRole();
        },
        mounted() {
            this.username = localStorage.getItem('sUsername');
            // if (!this.username) {
            //     this.$router.push('/');
            // }

            let userIcon = localStorage.getItem('sIcon');
            if (userIcon !== 'null') {
                this.userIcon = userIcon;
            }
        },
        watch: {
            '$route'(to, from) {
                if (to.name.indexOf('teacherCourseIndex') !== -1) {
                    this.activeIndex = 'qualityCourse';
                } else if (to.name.indexOf('studentCourseIndex') !== -1) {
                    this.activeIndex = 'qualityCourse';
                } else {
                    this.activeIndex = to.name;
                }

                if (localStorage.getItem('sUsername')) {
                    this.isLogin = true;
                } else {
                    this.isLogin = false;
                }
				// 监听角色
				this.watchRole();
            }
        },
        methods: {
			watchRole() {
				this.role = parseInt(localStorage.getItem('role'));
			},
            logout(command) {
                this.$shttp.axiosGet(this.$api.logout, (res) => {
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: res.msg,
                            onClose: ()=> {
                                localStorage.clear()
                                if (this.$route.path === '/'){
                                    window.location.reload();
                                } else {
                                    this.$router.push('/');
                                }
                            }
                        })
                    } else {
                        this.$message({
                            type: 'warning',
                            duration: 1000,
                            message: res.msg,
                            onClose: ()=> {
                                localStorage.clear()
                                if (this.$route.path === '/'){
                                    window.location.reload();
                                } else {
                                    this.$router.push('/');
                                }
                            }
                        })
                    }
                })
            },
            selectRouter(key, keyPath) {
                switch (key) {
                    case 'qualityCourse':
                        this.activeIndex = key;
                        break;
                    case 'qualityCoursePublicClass':
                        this.activeIndex = key;
                        break;
                    case 'qualityEducational':
                        this.activeIndex = key;
                        break;
                    case 'classRecord':
                        this.activeIndex = key;
                        break;
                    case 'myClass':
                        this.activeIndex = key;
                        break;
                    default:
                        this.activeIndex = null;
                        let elements = document.getElementsByClassName('el-menu-item');
                        for (let i=0; i<elements.length; i++) {
                            elements[i].className = 'el-menu-item';
                        }
                        break;
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .header-wrapper {
        width: 100%;
        background: #fff;
        border-bottom: 1px solid #dcdfe6;
        .header {
            position: relative;
            width: 1200px;
            height: 60px;
            line-height: 60px;
            margin: 0 auto;
            display: flex;
            .nav {
                flex: 1;
                width: 1%;
                border-bottom: none;
                .el-menu-item {
                    padding: 0;
                    .nav-item {
                        display: block;
                        padding: 0 20px;
                    }
                }
            }
            .logo {
                /*width: 200px;*/
                font-size: 18px;
                font-weight: 700;
                margin-right: 20px;
                overflow: hidden;
            }
            .user {
                text-align: right;
                margin-left: 20px;
                span {
                    font-size: 16px;
                    margin-right: 20px;
                }
            }
        }
    }
    .quality-course-container {
        .header-wrapper {
            display: block;
            z-index: 1001;
            height: 60px;
            width: 100%;
            box-shadow: 0 0 5px rgba(0,0,0,0.1), 0 0 5px rgba(0,0,0,0.1);
            background: #fff;
            /*min-width: 1000px;*/
            border-bottom: none;
            .header {
                width: 100%;
                padding-right: 70px;
                padding-left: 70px;
                box-sizing: border-box;
                .logo {
                    width: 200px;
                    height: 60px;
                    cursor: pointer;
                    margin: 0;
                    /*height: 100%;*/
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-right: 8px;
                    img {
                        /*width: 130px;
                        max-width: 100%;*/
                        /*height: auto;*/
                        /*width: 100%;*/
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .user {
                    display: flex;
                    .m-userinfo {
                        a {
                            display: block;
                            font-size: 16px;
                            color: #666;
                        }
                    }
                }
                .user .header-el-dropdown{
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    padding: 10px 0;
                    .user-text {
                        line-height: 40px;
                        margin-left: 10px;
                    }
                    img {
                        display: block;
                        width: 36px;
                        height: 36px;
                        border-radius: 50%;
                    }
                }
            }
        }
        .el-dropdown-menu {
            ::v-deep li {
                padding: 0 25px 0 10px;
                height: 27px;
                line-height: 27px;
                font-size: 14px;
                color: #666;
                &:hover {
                    color: #4A90E2;
                    background-color: transparent;
                }
                i {
                    font-size: 16px;
                    color: #666;
                }
            }
        }
    }
    .user-info {
        display: flex;
        align-items: center;
        .user-avatar {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #ddd;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .el-dropdown {
            .user-text {
                display: flex;
                align-items: center;
                &:hover {
                    color: #005496;
                }
                .user-name {
                    max-width: 80px;
                    display: block;
                    cursor: pointer;
                    &:focus {
                        outline: none;
                    }
                }
            }
            padding-left: 20px;
        }
    }
    @media (min-width: 576px) {
        .quality-course-container .container {
            max-width: 540px;
        }
    }
    @media (min-width: 768px) {
        .quality-course-container .container {
            max-width: 720px;
        }
    }
    @media (min-width: 992px) {
        .quality-course-container .container {
            max-width: 960px;
        }
    }
    @media (min-width: 1200px) {
        .quality-course-container .container {
            max-width: 1192px;
        }
    }
</style>