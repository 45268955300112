const ws = {
    type: 2, //swoole: 1 端口号：9501；workerman: 2 端口号：8282
    ip: localStorage.getItem('ip') ? localStorage.getItem('ip') : '47.97.113.174',
    // ip: '116.198.161.21',
    port: 9501,
    url: '',
    baseUrl: 'http://yike.e-class.me/'
}
ws.port = (ws.type === 1) ? '9501' : '8282';
ws.url = ws.ip + ':' + ws.port;

export default ws;