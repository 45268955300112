<template>
    <div class="course-container">
        <div class="course-container-index">
            <div class="course-left">
                <el-select v-model="select_course" placeholder="请选择课程" class="select-course" @change="chooseCourse">
                    <el-option v-for="item in adminCourseList" :key="item.course_id" :label="item.course_name"
                               :value="item.course_id"></el-option>
                </el-select>
                <div class="item-title" :class="isChosen === true?'choseAll':''" @click="choseAll">
                    <span class="text">课程总览</span>
                </div>
                <el-scrollbar class="zhang-jie" :native="false">
                    <div class="zhang-jie-item" :class="setClass(zhangIndex)"
                         v-for="(zhangItem, zhangIndex) in courseZhangJie">
                        <div class="zhang"
                             :title="`第${zhangIndex+1}章：${zhangItem.name}（${zhangItem.children.length}节）`">
                            <span class="text">第{{zhangIndex+1}}章：{{zhangItem.name}}（{{zhangItem.children.length}}节）</span>
                        </div>
                        <div class="jie-box">
                            <div class="jie-inside-box">
                                <div class="jie"
                                     :class="{current: currentJie === jieIndex && chapterIndex == zhangIndex}"
                                     v-for="(jieItem, jieIndex) in zhangItem.children"
                                     @click="clickJie(zhangIndex, jieIndex)"
                                     :title="`0${jieIndex+1} | ${jieItem.name}`">
                                    0{{jieIndex+1}} | {{jieItem.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
            <div class="course-right">
                <div class="container">
                    <router-view ref="childItem" v-if="activeName === 'adminFeatured' || 'adminCourseIndex'"
                                 :courseInfos="courseInfos"
                                 :nodeIndex="nodeIndex" :chapterIndex="chapterIndex"/>
                    <router-view v-else/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from "underscore";
    import CourseIndex from 'components/schoolAdministrator/course/CourseIndex.vue'
    import Featured from 'components/schoolAdministrator/course/Featured.vue'
    import CourseWare from 'components/schoolAdministrator/course/CourseWare.vue'
    import Textbook from 'components/schoolAdministrator/course/Textbook.vue'
    import MicroLesson from 'components/schoolAdministrator/course/MicroLesson.vue'
    import Animation from 'components/schoolAdministrator/course/Animation.vue'
    import {mapActions} from 'vuex';

    export default {
        name: "Course",
        data() {
            return {
                isChosen: true,
                activeName: 'adminCourseIndex',
                //选择的课程
                select_course: '',
                //课程列表
                adminCourseList: [],
                //章节
                courseZhangJie: [],
                //
                currentJie: 0,
                //总管理员分配的章节
                allocatedCourse: [],
                allocatedCourseNum: null,
                //课程介绍
                courseInfos: {},
                zhang_arr: [],
                nodeIndex: 0,
                chapterIndex: 0,
            }
        },
        components: {
            Featured,
            CourseWare,
            Textbook,
            MicroLesson,
            Animation,
            CourseIndex
        },
        created() {
            this.activeName = this.$route.meta.tabName;
            this.getAdminCourseList();
            if (this.activeName === 'adminCourseIndex') {
                this.isChosen = true;
                this.currentJie = '';
            }
            if (this.activeName === 'adminFeatured' && this.isChosen == true) {
                this.isChosen = false;
                this.chapterIndex = localStorage.getItem('adminChapter');
                this.nodeIndex = localStorage.getItem('adminNode');
                this.currentJie = Number(this.nodeIndex);
            }
        },
        watch: {
            $route(route) {
                if (route.name === 'adminCourseIndex') {
                    this.activeName = 'adminCourseIndex';
                    this.isChosen = true;
                    this.currentJie = '';
                } else if (route.name === 'adminFeatured') {
                    this.activeName = 'adminFeatured';
                    this.isChosen = false;
                }
            }
        },
        methods: {
            ...mapActions([
                'setCourseAttrC'
            ]),
            setClass(index) {
                if (this.zhang_arr.indexOf(index + 1) !== -1) {
                    return 'isAllocatedClass';
                } else {
                    return '';
                }

            },
            //获取课程列表
            getAdminCourseList() {
                this.$http.axiosGet(this.$api.admin_sclAdminCourseList, (res) => {
                    if (res.code === 200) {
                        this.adminCourseList = res.data.course_list;
                        if (this.$route.query.course_id) {
                            this.select_course = Number(this.$route.query.course_id);
                        } else {
                            this.select_course = Number(this.adminCourseList[0].course_id);
                            this.$router.push({
                                path: '/admin/course/courseindex',
                                query: {
                                    course_id: this.select_course
                                }
                            });
                        }
                        this.getCourseInfo(this.select_course);
                        let tmpObj = JSON.parse(res.data.course_data);
                        for (let j = 0; j < tmpObj.length; j++) {
                            var s = tmpObj[j][1];
                            this.allocatedCourse.push(tmpObj[j]);
                        }
                        let zhang_arr = [];
                        _.each(this.allocatedCourse, (_item) => {
                            let [course_id, zhang_index] = _item;
                            if (Number(course_id) === Number(this.select_course)) {
                                zhang_arr.push(zhang_index + 1);
                            }
                        });
                        this.zhang_arr = zhang_arr;
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            choseAll() {
                this.isChosen = true;
                this.currentJie = '';
                this.$router.push({
                    path: '/admin/course/courseindex',
                    query: {
                        course_id: this.select_course
                    }
                })
            },
            //选择课程
            chooseCourse(val) {
                this.currentJie = 0;
                this.chapterIndex = 0;
                this.nodeIndex = 0;
                this.$router.push({
                    path: '/admin/course/courseindex',
                    query: {
                        course_id: val,
                    },
                });
                this.getCourseInfo(val);
                this.getAdminCourseList();
            },
            //获取课程详情
            getCourseInfo(courseId) {
                let params = {
                    id: courseId
                };
                this.$http.axiosGetBy(this.$api.courseinfo, params, (res) => {
                    if (res.code === 200) {
                        this.courseZhangJie = res.data.course_content;
                        this.courseInfos = res.data;
                    }
                }, (error) => {
                    console.log(error);
                })
            },
            //
            clickJie(index, jIndex) {
                if (this.zhang_arr.indexOf(index + 1) !== -1) {
                    this.setCourseAttrC({
                        course_name: this.courseInfos.course_name,
                        chapter_name: this.courseZhangJie[index].name,
                        node_name: this.courseZhangJie[index].children[jIndex].name,
                        node_index: jIndex,
                        chapter_index: index,
                    });
                    this.currentJie = jIndex;
                    this.chapterIndex = index;
                    this.nodeIndex = jIndex;
                    localStorage.setItem('adminChapter', index);
                    localStorage.setItem('adminNode', jIndex);
                    this.$router.push({
                        path: '/admin/course/featured',
                        query: {
                            course_id: this.select_course,
                        }
                    });
                } else {
                    this.$message({
                        type: 'warning',
                        message: '该章内容未授权',
                        duration: 1000
                    });
                }


            },
            handleClick(tab, event) {
                this.$router.push({
                    name: tab.name,
                    query: {
                        course_id: this.select_course
                    },
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .course-container {
        flex: 1;
        height: 1%;

        .course-container-index {
            display: flex;
            justify-content: start;
            position: relative;
            height: 100%;
            padding: 20px 70px;
            box-sizing: border-box;
            background: #F2F8FF;
            overflow: auto;
        }

        .course-left {
            display: flex;
            flex-direction: column;
            width: 300px;
            min-width: 300px;
            height: 100%;
            background-color: #fff;
            margin-right: 20px;

            .select-course {
                width: 260px;
                height: 55px;
                background: #F5F7FA;
                border: 1px solid #DDDDDD;
                border-radius: 6px;
                margin: 20px;

                ::v-deep .el-input__inner {
                    color: #333;
                    border: transparent;
                    background-color: #F5F7FA;
                    height: 55px;
                }
            }

            .item-title {
                position: relative;
                font-size: 15px;
                margin: 0 20px;
                height: 40px;
                display: flex;
                align-items: center;

                &:before {
                    content: '';
                    position: absolute;
                    top: 17px;
                    width: 6px;
                    height: 10px;
                    background: #005469;
                    border-radius: 3px;
                }

                .text {
                    margin-left: 15px;
                    font-size: 16px;
                }

                &.choseAll {
                    background: #005469;
                    border-radius: 20px;
                    cursor: pointer;
                    color: #ffffff;

                    &:before {
                        content: none;
                    }
                }
            }

            .zhang-jie {
                flex: 1;
                height: 1%;

                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }

                .zhang-jie-item {
                    padding: 0 20px 20px;
                    color: #999;

                    &:nth-child(1) {
                        padding-top: 15px;
                    }

                    .zhang {
                        height: 30px;
                        line-height: 30px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        position: relative;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 9px;
                            width: 6px;
                            height: 10px;
                            background: #005469;
                            border-radius: 3px;
                        }

                        .text {
                            margin-left: 15px;
                            font-size: 16px;
                        }

                    }

                    .jie-box {
                        background: #F5F7FA;

                        .jie-inside-box {
                            padding: 8px 0;
                        }
                    }

                    .jie {
                        cursor: pointer;
                        height: 40px;
                        line-height: 30px;
                        /*color: #005469;*/
                        padding-left: 40px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                    }

                    .current {
                        background: #005469;
                        border-radius: 20px;
                        color: #ffffff;
                    }

                    &.isAllocatedClass {
                        color: #333;

                        .jie {
                            cursor: pointer;

                            &:hover {
                                color: #005469;
                            }
                        }
                    }
                }
            }
        }

        .course-right {
            display: flex;
            flex-direction: column;
            position: relative;
            flex: 1;
            width: 1%;

            .container {
                flex: 1;
                height: 1%;
                width: 100%;
            }

            .index-tabs {
                width: 100%;
                height: auto;
                background: transparent;
                padding: 0;

                ::v-deep .el-tabs__header {
                    background-color: #fff;
                    padding: 0 20px;

                    .el-tabs__nav {
                        .el-tabs__item {
                            height: 60px;
                            line-height: 60px;
                        }
                    }

                    .el-tabs__nav-wrap::after {
                        background-color: transparent;
                    }
                }

                ::v-deep .el-tabs__content {
                    background: #f4f5f5;
                }
            }

            .search-input {
                position: absolute;
                top: 15px;
                right: 20px;
                width: 270px;

                ::v-deep .el-input__inner {
                    height: 30px;
                    line-height: 30px;
                }
            }
        }
    }
</style>