const Api = {
	matchAdminLogin: 'admin/matchAdminLogin',
	logout: 'admin/logout',
    resourceClassList:'admin/resourceClassList',
	resourceClassAdd:'admin/resourceClassAdd',
	resourceClassEdit:'admin/resourceClassEdit',
	resourceClassDel:'admin/resourceClassDel',
	resourceTypeList: 'admin/resourceTypeList',
	resourceList: 'admin/resourceList',
	uploadFile: 'admin/uploadFile',
	saveResource: 'admin/saveResource',
	resourceDelete: 'admin/resourceDelete',
	editSave: 'admin/editSave',
	delFile: 'admin/delFile',
	download: 'admin/download',
	createCourse: 'admin/saveCourse',
	courses: 'admin/courses',
	courseContent: 'admin/courseinfo',
	deletecourse: 'admin/deletecourse',
	courserType: 'admin/courseTypeList',
	checkToken: 'admin/checkToken',
    JdStudentLogin: 'admin/JdStudentLogin',
	courseinfo: 'admin/courseinfo',
	blackBbUrl: 'blackboard',
	mindMapUrl: 'mindmap',
	teacherLogin: 'admin/matchTeacherLogin',
	addSchool: 'school/addSchool',
	showSchool: 'school/showSchool',
	saveSchool: 'school/saveSchool',
	delSchool: 'school/delSchool',
	adminShowSchool: 'admin/showSchool',
	showAdmin: 'admin/showAdmin',
	addAdmin: 'admin/addAdmin',
	saveAdmin: 'admin/saveAdmin',
	schoolList: 'admin/schoolList',
	delAdmin: 'admin/delAdmin',
	showTeacher: 'teacher/showTeacher',
	addTeacher: 'teacher/addTeacher',
	saveTeacher: 'teacher/saveTeacher',
	delTeacher: 'teacher/delTeacher',
	resetPwd: 'teacher/resetPwd',
	showStudent: 'jd/showStudent',
	showClass: 'jd/showClass',
	// addStudent: 'student/addStudent',
	saveStudent: 'jd/saveStudent',
	delStudent: 'jd/delStudent',
	resetStuPwd: 'jd/resetStuPwd',
	resetAdminPwd: 'admin/resetPwd',
	bj_sx: 'student/bj_sx',
    cl_list: 'jd/cl_list',
	delClass: 'jd/delClass',
	saveClass: 'jd/saveClass',
    statistics: 'jd/statistics',
	addClass: 'class/addClass',
	getCourseProgressList: 'jd/getCourseProgressList',
    getTeacherCourseByClass: 'jd/getTeacherCourseByClass',
	startCourse: 'jd/startCourse',
	endingCourse: 'jd/endingCourse',
    stuSignIn: 'jd/stuSignIn',
	showTag: 'exam/showTag',
	updTag: 'exam/updTag',
	addTag: 'exam/addTag',
	updSaveTag: 'exam/updSaveTag',
	delTag: 'exam/delTag',
	delTopic: 'exam/delTopic',
	showTopic: 'exam/showTopic',
	getClassRecord: 'jd/getClassRecord',
	showSignInList: 'jd/showSignInList',
    show_tag: 'exam/show_tag',
    addTopic: 'exam/addTopic',
    updTopic: 'exam/updTopic',
    updSaveTopic: 'exam/updSaveTopic',
	teacherIssueQuiz: 'jd/teacherIssueQuiz',
	screenTestPaper: 'exam/screenTestPaper',
	deleteTestPaper: 'exam/deleteTestPaper',
	saveTestPaper: 'exam/saveTestPaper',
	setIncTopic: 'exam/setIncTopic',
	findTestPaper: 'exam/findTestPaper',
    showSx: 'jd/showSx',
	showPaperContent: 'jd/showPaperContent',
	showClassSignIn: 'jd/showClassSignIn',
	studentSubmitTest: 'jd/studentSubmitTest',
	getQuizList: 'jd/getQuizList',
	seeStudentQuizList: 'jd/seeStudentQuizList',
    saveSx: 'jd/saveSx',
    deleteSx: 'jd/deleteSx',
    findBySx: 'jd/findBySx',
    showStuSxList: 'jd/showStuSxList',
    showCourseScreenList: 'jd/showCourseScreenList',
    stuPutSx: 'jd/stuPutSx',
	exmapapers: 'exam/exmapapers',
	getRecordList: 'jd/getRecordList',
	seeUnapprovedStudentQuiz: 'jd/seeUnapprovedStudentQuiz',
	TeacherCheckAskAnswer: 'jd/TeacherCheckAskAnswer',
	seeStudentQuiz: 'jd/seeStudentQuiz',
    showDiscussList: 'jd/showDiscussList',
    showDiscussNums: 'jd/showDiscussNums',
	uploadKindeditor: 'admin/uploadKindeditor',
    bindClient: 'jd/bindClient',
    sendMessage: 'jd/sendMessage',
	newsWeUpload: 'news/weimg',
	pdfPreview: 'pdf/',
	newsUpload: 'news/upload',
	newsDesImg: 'news/desimg',
	newsAdd: 'news/add',
	newsList: 'news/list',
	newsDel: 'news/del',
	newsInfo: 'news/info',
	bannerList: 'banner/list',
	bannerUpload: 'banner/upload',
	bannerAdd: 'banner/add',
	bannerDel: 'banner/del',
	import: 'tools/import',
	excelTemplateUrl: 'uploads/excel/template/template.xlsx',
	divideClass: 'admin/divideClass',
	schoolInfo: 'school/editSchool',
	getExcel: 'exam/getExcel',
    app_showApp: 'app/showApp',
    app_saveApp: 'app/saveApp',
    app_delApp: 'app/delApp',
	materialTypeList: 'admin/materialTypeList',
	saveProject: 'project/saveProject',
	uploadProjectFile: 'project/uploadFile',
	AdminProjectList: 'project/AdminProjectList',
	addProjectResource: 'project/addProjectResource',
	delProjectResource: 'project/delProjectResource',
	checkProjectName: 'project/issetName',
	getMatingTypeList: 'project/materialTypeList',
	delProject: 'project/delProject',
	getProjectDetail: 'project/projectDetail',
	getCourseList: 'project/courseList',
	getResource: 'project/getResource',
    project_AdminProjectList: 'project/AdminProjectList',
    admin_allocationProject: 'admin/allocationProject',
    admin_allocationAppCenter: 'admin/allocationAppCenter',
    admin_courseLists: 'admin/courseLists',
    admin_sclAdminCourseList: 'admin/sclAdminCourseList',
    meterial_mtlTypeList: 'meterial/mtlTypeList',
    meterial_uploadFile: 'meterial/uploadFile',
    meterial_saveMeterial: 'meterial/saveMeterial',
    meterial_showMeterialList: 'meterial/showMeterialList',
	meterial_personal: "meterial/personal",
	admin_showTeaCourse: 'admin/showTeaCourse',
	showTeaCourse:'admin/showTeaCourse',
    school_allocationCourseToTea: 'school/allocationCourseToTea',
    teacher_editTeacher: 'teacher/editTeacher',
	jd_getTeacherCourseOne: 'jd/getTeacherCourseOne',
	getCourseCustom: 'course/getCourseCustom',
    project_projectList: 'project/projectList',
	project_projectListNoPage: 'project/projectListNoPage',
    project_dianZan: 'project/dianZan',
    course_putDrillData: 'course/putDrillData',
    drill_showDrillList: 'drill/showDrillList',
    drill_putDrillData: 'drill/putDrillData',
    drill_showStuPutDrillList: 'drill/showStuPutDrillList',
	course_getcoursecustom: 'course/getCourseCustom',
	saveCourseCustom: 'course/saveCourseCustom',
    drill_teaCommentStuDrill: 'drill/teaCommentStuDrill',
    drill_issuedDrill: 'drill/issuedDrill',
    drill_delDrill: 'drill/delDrill',
    drill_showProjectPutData: 'drill/showProjectPutData',
	getAllocation: 'meterial/getAllocationApp',
	delMeterial: 'meterial/delMeterial',
    jd_delQuiz: 'jd/delQuiz',
    jd_getStuSignInDetails: 'jd/getStuSignInDetails',
	jd_getStuInfo: 'jd/getStuInfo',
    jd_getStuQuizDetails: 'jd/getStuQuizDetails',
    jd_getStuSxDetails: 'jd/getStuSxDetails',
	contents_uploads: 'contents/uploads',
	contents_saveCourseHome: 'contents/saveCourseHome',
	course_getCourseTemp: 'course/getCourseTemp',
	contents_showCourseContent: 'contents/showCourseContent',
	course_saveCourseTemp:'course/saveCourseTemp',
	course_delCourseTemp:'course/delCourseTemp',
    courseware_uploadWareFiles: 'courseware/uploadWareFiles',
    courseware_saveCourseWare: 'courseware/saveCourseWare',
    courseware_showCourseWare: 'courseware/showCourseWare',
    admin_uploadsClassifyImg: 'admin/uploadsClassifyImg',
    admin_projectClassList: 'admin/projectClassList',
	project_tinyimgupload: 'project/tinyImgUpload',
    courseware_delCourseWare: 'courseware/delCourseWare',
	exam_topictemplate: 'exam/topictemplate'
}

export default Api;
