<template>
    <div class="header-wrapper">
        <div class="header">
            <!--<a href="javascript:;" class="logo">京东课程平台</a>-->
            <a href="javascript:;" class="logo">
                <img src="../../assets/images/yiyatong_logo.png" alt="">
            </a>
            <el-menu :default-active="activeIndex" class="nav" mode="horizontal">
                <el-menu-item index="adminIndex" v-if="role === 1">
                    <router-link class="nav-item" to="/admin/index">素材中心</router-link>
                </el-menu-item>
                <el-menu-item index="adminCourseManage" v-if="role === 1">
                    <router-link class="nav-item" to="/admin/courseManage/index">课程管理</router-link>
                </el-menu-item>
                <!--                <el-menu-item index="school">-->
                <!--                    <router-link class="nav-item" to="">学校管理</router-link>-->
                <!--                </el-menu-item>-->
                <el-menu-item index="adminClassManage" v-if="role === 1">
                    <router-link class="nav-item" to="/admin/classManage/index">分类管理</router-link>
                </el-menu-item>

                <el-menu-item index="adminCourse" v-if="role === 2">
                    <router-link class="nav-item" to="/admin/course/courseindex">课程</router-link>
                </el-menu-item>
                <el-menu-item index="adminEducationManage">
                    <router-link class="nav-item" to="/admin/educationManage/index">教务管理</router-link>
                </el-menu-item>
                <el-menu-item index="adminNewsManage">
                    <router-link class="nav-item" to="/admin/newsManage/index">新闻管理</router-link>
                </el-menu-item>
                <el-menu-item index="adminCarouselManage">
                    <router-link class="nav-item" to="/admin/carouselManage/index">轮播管理</router-link>
                </el-menu-item>
                <el-menu-item index="adminApplicationCenter" v-if="role === 1">
                    <router-link class="nav-item" to="/admin/applicationCenter/index">应用中心</router-link>
                </el-menu-item>
                <el-menu-item index="adminProjectLibrary" v-if="role === 2">
                    <router-link class="nav-item" to="/admin/projectLibrary/index">项目库</router-link>
                </el-menu-item>
            </el-menu>
            <div class="user">
                <span>{{username}}</span>
				<a href="javascript:;" @click="logout">退出</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HeaderModule",
        data() {
            return {
				username: '',
                activeIndex: '',
				activeMaterialIndex: ['adminIndex', 'addExamPaper', 'addSubject', 'editSubject','addProject', 'projectDetail'],
                // activeNewsIndex: ['adminNewsManage', 'adminNewsAdd']
            }
        },
        watch: {
            $route(route) {
                if (this.activeMaterialIndex.indexOf(route.name) !== -1) {
                    this.activeIndex = 'adminIndex';
                } else {
                    this.activeIndex = route.matched[1].name;
                }
            },
        },
        computed: {
            role() {
                return localStorage.getItem('role') ? Number(localStorage.getItem('role')) : 1;
            }
        },
        created() {
			this.username = localStorage.getItem('username');
			if (!this.username) {
				this.$router.push('/admin/login');
			}
            // this.activeIndex = this.$route.name;
			this.checkToken();
            if (this.activeMaterialIndex.indexOf(this.$route.name) !== -1) {
                this.activeIndex = 'adminIndex';
            } else {
                this.activeIndex = this.$route.matched[1].name;
            }
        },
		methods: {
            checkToken() {
                let role = Number(localStorage.getItem('role'));
                this.$http.axiosGet(this.$api.checkToken, (res) =>  {
                    if (res.code === 400 || role === 3 || role === 4) {
                        this.$message({
                            type: 'warning',
                            message: '用户登录失效，请重新登录！',
                            duration: 500,
                            onClose: () => {
                                localStorage.removeItem('token');
                                localStorage.removeItem('username');
                                this.$router.push('/admin/login');
                            }
                        });
                    }
                }, (err) => {
                    this.$message({
                        type: 'warning',
                        message: res.msg,
                        duration: 500,
                        onClose: () => {
                            localStorage.removeItem('token');
                            localStorage.removeItem('username');
                            this.$router.push('/admin/login');
                        }
                    });
                    console.log(err);
                })
            },
			logout() {
                let _this = this;
			    this.$http.axiosGet(this.$api.logout, (res) => {
			        localStorage.clear();
			        if (res.code === 200) {
			            this.$message({
                            type: 'success',
                            duration: 500,
                            message: res.msg,
                            onClose: () => {
                                _this.$router.push(res.data.url);
                            }
                        })
                    } else {
			            this.$message({
                            type: 'warning',
                            duration: 500,
                            message: res.msg,
                            onClose: () => {
                                _this.$router.push('/admin/login')
                            }
                        })
                    }
                }, (err) => {
			        console.log(err);
                });
			}
		}
    }
</script>

<style lang="scss" scoped>
    .header-wrapper {
        width: 100%;
        background: #fff;
        border-bottom: 1px solid #dcdfe6;
        .header {
            position: relative;
            width: 1200px;
            height: 59px;
            line-height: 59px;
            margin: 0 auto;
            display: flex;
            .nav {
                flex: 1;
                width: 1%;
                border-bottom: none;
                .el-menu-item {
                    padding: 0;
                    .nav-item {
                        display: block;
                        padding: 0 20px;
                    }
                }
            }
            .logo {
                /*width: 200px;*/
                font-size: 18px;
                font-weight: 700;
                margin-right: 20px;
                overflow: hidden;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .user {
                text-align: right;
                margin-left: 20px;
                span {
                    font-size: 16px;
                    margin-right: 20px;
                }
            }
        }
    }
</style>
