import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "../theme/index.css"
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import 'assets/style/iconfont.css';
import 'assets/style/index.scss';
import Http from './utils/http.js';
import Api from './utils/api.js';
import sHttp from './utils/student_http';
import tHttp from './utils/teacher_http';
import FileHttp from './utils/file_http';
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import Common from './utils/common.js';
import VueKindEditor from 'vue-kindeditor'
import 'kindeditor/kindeditor-all-min.js'
import 'kindeditor/themes/default/default.css'
import lockr from 'lockr';
import Config from '@/utils/wsConfig'
import tinymce from 'tinymce'
import VueTinymce from '@packy-tang/vue-tinymce'
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueKindEditor);
import echarts from "echarts";
Vue.prototype.$echarts = echarts;

Vue.prototype.$http = Http;
Vue.prototype.$api = Api;
Vue.prototype.$fileHttp = FileHttp;
Vue.prototype.$shttp = sHttp;
Vue.prototype.$video = Video;
Vue.prototype.$common = Common;
Vue.prototype.$lockr = lockr;
Vue.prototype.$thttp = tHttp;
Vue.prototype.$config = Config;
Vue.prototype.$tinymce = tinymce // 将全局tinymce对象指向给Vue作用域下
Vue.use(VueTinymce)              // 安装vue的tinymce组件

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
