import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout/admin/index.vue'
import AdminCourseLayout from '../layout/admin/CourseIndex.vue'
import qualityCourseLayout from '../layout/qualityCourse/index.vue'
import layoutContent from '../layout/student/LayoutContent';
import homeLayout from '../layout/qualityCourse/home.vue';
import loginLayout from '../layout/qualityCourse/login';
import newsLayout from '../views/admin/NewsManage';
import studentProjectLayout from '../layout/student/studentProjectLayout';
import CourseLayout from '../views/admin/Course';
import TeacherCourseLayout from '../views/admin/TeacherCourseLayout';
import studentCourseLayout from '../views/student/Course.vue';
import Router from 'vue-router'
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import("@/views/index/Index"),
        meta: {
            title: "怡亚通",
        }
    },
  {
    path: '/admin',
    redirect: '/admin/index',
    component: Layout,
    children: [
		{
		  path: '/admin/index',
		  name: 'adminIndex',
		  component: () => import('../views/admin/Index.vue'),
		  meta: {
			title: '素材中心'
		  }
		},
		{
			path: '/admin/index/addSubject',
			name: 'addSubject',
			component: () => import('../components/material/MaterialExamSubjectAdd.vue'),
			meta: {
				title: '添加试题'
			}
		},
		{
			path: '/admin/index/editSubject',
			name: 'editSubject',
			component: () => import('../components/material/MaterialExamSubjectEdit.vue'),
			meta: {
				title: '编辑试题'
			}
		},
		{
			path: '/admin/index/examPaper',
			name: 'addExamPaper',
			component: () => import('../components/material/examPaper/addExamPaper.vue'),
			meta: {
				title: '素材中心-添加试卷'
			}
		},
        {
			path: '/admin/index/addProject',
			name: 'addProject',
			component: () => import('../components/material/MaterialProjectAdd'),
			meta: {
				title: '新建项目'
			}
		},
        {
            path: '/admin/index/projectDetail',
            name: 'projectDetail',
            component: () => import('../components/material/MaterialProjectDetail'),
            meta: {
                title: '项目详情'
            }
        }
	]
  },
  {
    path: '/admin/classManage',
    component: Layout,
    children: [{
      path: '/admin/classManage/index',
      name: 'adminClassManage',
      component: () => import('../views/admin/ClassManage.vue'),
      meta: {
        title: '分类管理'
      }
    }]
  },
  {
    path: '/admin/courseManage',
    component: Layout,
    children: [{
      path: '/admin/courseManage/index',
      name: 'adminCourseManage',
      component: () => import('../views/admin/CourseManage.vue'),
      meta: {
        title: '课程管理'
      }
    }]
  }, {
    path: '/admin/courseIndexAdd',
    component: AdminCourseLayout,
    children: [{
        path: '/admin/courseIndexAdd/index',
        name: 'adminCourseIndexAdd',
        component: () => import('../views/admin/CourseIndexAdd.vue'),
        meta: {
            title: '课程编辑'
        }
    }]
  },
  {
    path: '/admin/educationManage',
    component: Layout,
    children: [{
      path: '/admin/educationManage/index',
      name: 'adminEducationManage',
      component: () => import('../views/admin/EducationManage.vue'),
      meta: {
        title: '教务管理'
      }
    }]
  },
  {
    path: '/admin/course',
    component: Layout,
    children: [{
      path: '/admin/course/featured',
      name: 'adminCourse',
      component: CourseLayout,
      children: [
          {
              path: '/admin/course/courseindex',
              name: 'adminCourseIndex',
              component: () => import('../components/schoolAdministrator/course/CourseIndex.vue'),
              meta: {
                  title: '课程-首页',
                  tabName: 'adminCourseIndex'
              }
          },
          {
              path: '/admin/course/featured',
              name: 'adminFeatured',
              component: () => import('../components/schoolAdministrator/course/Featured.vue'),
              meta: {
                  title: '课程-精选',
                  tabName: 'adminFeatured'
              }
          },
          {
              path: '/admin/course/CourseWareDetail',
              name: 'adminCourseWareDetail',
              component: () => import('../components/schoolAdministrator/course/CourseWareDetail.vue'),
              meta: {
                  title: '课程-精选课程',
                  tabName: 'adminCourseWareDetail'
              }
          },
          {
              path: '/admin/course/featureddetail',
              name: 'adminFeaturedDetail',
              component: () => import('../components/schoolAdministrator/course/FeaturedDetail.vue'),
              meta: {
                  title: '课程-精选课程',
                  tabName: 'adminFeatured'
              }
          },
          {
              path: '/admin/course/MatingDetail',
              name: 'adminteacherMatingDetail',
              component: () => import('../components/schoolAdministrator/course/MatingDetail.vue'),
              meta: {
                  title: '课程--配套材料',
                  tabName: 'adminFeatured'
              }
          },
          {
              path: '/admin/course/materialslist',
              name: 'adminMaterialsList',
              component: () => import('../components/schoolAdministrator/course/MaterialsList.vue'),
              meta: {
                  title: '课程--配套材料',
                  tabName: 'adminFeatured'
              }
          },
          {
              path: '/admin/course/courseware',
              name: 'adminCourseWare',
              component: () => import('../components/schoolAdministrator/course/CourseWare.vue'),
              meta: {
                  title: '课程-课件',
                  tabName: 'adminCourseWare'
              }
          },
          {
              path: '/admin/course/textbook',
              name: 'adminTextbook',
              component: () => import('../components/schoolAdministrator/course/Textbook.vue'),
              meta: {
                  title: '课程-教材',
                  tabName: 'adminTextbook'
              }
          },
          {
              path: '/admin/course/microlesson',
              name: 'adminMicroLesson',
              component: () => import('../components/schoolAdministrator/course/MicroLesson.vue'),
              meta: {
                  title: '课程-微课',
                  tabName: 'adminMicroLesson'
              }
          },
          {
              path: '/admin/course/animation',
              name: 'adminAnimation',
              component: () => import('../components/schoolAdministrator/course/Animation.vue'),
              meta: {
                  title: '课程-动画',
                  tabName: 'adminAnimation'
              }
          },

      ]
    },
    {
        path: '/admin/course/courseDetail',
        name: 'schoolAdminCourseDetail',
        component: () => import('../components/teacherCourse/TeacherCourseDetail.vue'),
        meta: {
            title: '课程-详情页',
            // tabName:'teacherCourseIndex'
        }
    }]
  },
  {
    path: '/admin/newsManage',
    component: Layout,
    children: [{
      path: '/admin/newsManage/index',
      name: 'adminNewsManage',
      component: newsLayout,
      children: [
        {
            path: '/admin/newsManage/index',
            name: 'adminNewsManageIndex',
            component: () => import('../views/admin/NewsIndex'),
            meta: {
                title: '新闻管理'
            }
        },
        {
            path: '/admin/newsManage/add',
            name: 'adminNewsAdd',
            component: () => import('../views/admin/NewsAdd'),
            meta: {
                title: '新闻管理'
            }
        }
      ]
    }]
  },
  {
    path: '/admin/carouselManage',
    component: Layout,
    children: [{
        path: '/admin/carouselManage/index',
        name: 'adminCarouselManage',
        component: () => import('../views/admin/CarouselManage'),
        meta: {
            title: '轮播管理'
        }
    }]
  },
  {
    path: '/admin/applicationCenter',
    component: Layout,
    children: [{
        path: '/admin/applicationCenter/index',
        name: 'adminApplicationCenter',
        component: () => import('../views/admin/applicationCenter'),
        meta: {
            title: '应用中心'
        }
    }]
  },
  {
    path: '/admin/projectLibrary',
    component: Layout,
    children: [{
        path: '/admin/projectLibrary/index',
        name: 'adminProjectLibrary',
        component: () => import('../views/admin/projectLibrary'),
        meta: {
            title: '项目库'
        }
    },{
        path: '/admin/projectLibrary/project',
        name: 'adminProject',
        component: () => import('../views/admin/Project'),
        meta: {
            title: '项目库'
        }
    }]
  },
  {
    path: '/admin/courseManage/qualityCourseCreate',
    name: 'adminCourseManageQualityCourseCreate',
    component: () => import('../components/createCourse/QualityCourseCreate.vue'),
    meta: {
      title: '创建精品课程'
    }
  },
  {
    path: '/admin/login',
    name: 'adminLogin',
    component: () => import('../views/admin/Login.vue'),
    meta: {
      title: '资源管理--欢迎登录'
    }
  },
    // {
    //     path: '/course',
    //     component: qualityCourseLayout,
    //     children: [{
    //         path: '/course/courseIndex',
    //         name: 'courseIndex',
    //         component: () => import('../views/qualityCourse/courseIndex.vue'),
    //         meta: {
    //             title: '课程'
    //         }
    //     }]
    // },
  {
    path: '/course/courseIndex',
    name: 'courseIndex',
    component: () => import('../views/qualityCourse/courseIndex.vue'),
    meta: {
      title: '课程'
    }
  },
  {
    path: '/course/inClass',
    name: 'courseIndex',
    component: () => import('../views/qualityCourse/InClass.vue'),
    meta: {
      title: '课程'
    }
  },
    {
    path: '/course/PaperDetail',
    name: 'coursePaperDetail',
    component: () => import('../views/qualityCourse/PaperDetail'),
    meta: {
      title: '小测详情'
    }
  },
    {
        path: '/exampaper/preview',
        name: 'exampaperPreview',
        component: () => import('../views/qualityCourse/examPaperPreView'),
        meta: {
            title: '试卷预览'
        }
    },
  {
    path: '/qualityCourse',
    component: qualityCourseLayout,
    children: [{
      path: '/qualityCourse/PublicClass',
      name: 'qualityCoursePublicClass',
      component: () => import('../components/qualityCourse/PublicClass.vue'),
      meta: {
        title: '公开课'
      }
    }]
  },
    {
    path: '/qualityCourse',
    component: qualityCourseLayout,
    children: [
        {
            path: '/qualityCourse/projectclassify',
            name: 'qualityCourseProjectIndex',
            component: studentProjectLayout,
            children: [
                {
                    path: '/qualityCourse/projectclassify',
                    name: 'qualityCourseProjectClassify',
                    component: () => import('../components/qualityCourse/ProjectClassify.vue'),
                    meta: {
                        title: '项目库分类'
                    }
                },
                {
                    path: '/qualityCourse/Project',
                    name: 'qualityCourseProject',
                    component: () => import('../components/qualityCourse/Project.vue'),
                    meta: {
                        title: '项目库列表'
                    }
                },
                {
                    path: '/qualityCourse/project/detail',
                    name: 'qualityCourseProjectDetail',
                    component: () => import('../components/qualityCourse/ProjectDetail.vue'),
                    meta: {
                        title: '项目详情'
                    }
                }
            ]
        }
    ]
  },
    {

        path: '/course/practical/detail',
        name: 'coursePracticalDetail',
        component: () => import('../views/qualityCourse/PracticalDetail.vue'),
        meta: {
            title: '实训详情'
        }
    },
    {
    path: '/qualityCourse',
    component: qualityCourseLayout,
    children: [{
      path: '/qualityCourse/PublicClassDetail',
      name: 'qualityCoursePublicClassDetail',
      component: () => import('../components/qualityCourse/PublicClassDetail.vue'),
      meta: {
        title: '公开课详情'
      }
    }]
  },
  {
    path: '/qualityCourse',
    component: qualityCourseLayout,
    children: [
        {

          path: '/qualityCourse/index',
          name: 'qualityCourse',
          component: studentCourseLayout,
          children: [
              {
                  path: '/qualityCourse/courseindex',
                  name: 'studentCourseIndex',
                  component: () => import('../components/Student/course/CourseIndex.vue'),
                  meta: {
                      title: '课程-首页',
                      tabName: 'studentCourseIndex'
                  }
              },
              {
                  path: '/qualityCourse/featured',
                  name: 'studentFeatured',
                  component: () => import('../components/Student/course/Featured.vue'),
                  meta: {
                      title: '课程-精选',
                      tabName: 'studentFeatured'
                  }
              },
              {
                  path: '/qualityCourse/featureddetail',
                  name: 'studentFeaturedDetail',
                  component: () => import('../components/Student/course/FeaturedDetail.vue'),
                  meta: {
                      title: '课程-精选-配套材料',
                      tabName: 'studentFeaturedDetail'
                  }
              },
              {
                  path: '/qualityCourse/courseware',
                  name: 'studentCourseWare',
                  component: () => import('../components/Student/course/CourseWare.vue'),
                  meta: {
                      title: '课程-课件',
                      tabName: 'studentCourseWare'
                  }
              },
              {
                  path: '/qualityCourse/textbook',
                  name: 'studentTextbook',
                  component: () => import('../components/Student/course/Textbook.vue'),
                  meta: {
                      title: '课程-教材',
                      tabName: 'studentTextbook'
                  }
              },
              {
                  path: '/qualityCourse/microlesson',
                  name: 'studentMicroLesson',
                  component: () => import('../components/Student/course/MicroLesson.vue'),
                  meta: {
                      title: '课程-微课',
                      tabName: 'studentMicroLesson'
                  }
              },
              {
                  path: '/qualityCourse/animation',
                  name: 'studentAnimation',
                  component: () => import('../components/Student/course/Animation.vue'),
                  meta: {
                      title: '课程-动画',
                      tabName: 'studentAnimation'
                  }
              },
          ],

     },
    {
        path: '/qualityCourse/courseDetail',
        name: 'qualityCourseDetail',
        component: () => import('../components/teacherCourse/TeacherCourseDetail.vue'),
        meta: {
            title: '课程-详情页',
            // tabName:'teacherCourseIndex'
        }
    },

        {
            path: '/qualityCourse/teacher/teacherFeatured',
            name: 'teacherCourse',
            component: TeacherCourseLayout,
            children: [{
                path: '/qualityCourse/teacher/index',
                name: 'teacherCourseIndex',
                component: () => import('../components/teacherCourse/teacherIndex.vue'),
                meta: {
                    title: '课程-首页',
                    tabName:'teacherCourseIndex'
                }
            },{
                path: '/qualityCourse/teacher/teacherFeatured',
                name: 'teacherFeatured',
                component: () => import('../components/teacherCourse/teacherFeatured.vue'),
                meta: {
                    title: '课程-精选',
                    tabName:'teacherFeatured'
                }
            },{
                path: '/qualityCourse/teacher/teacherCourseWare',
                name: 'teacherCourseWare',
                component: () => import('../components/teacherCourse/teacherCourseWare.vue'),
                meta: {
                    title: '课程-课件',
                    tabName:'teacherCourseWare'
                }
            },{
                path: '/qualityCourse/teacher/teacherCourseWareDetail',
                name: 'teacherCourseWareDetail',
                component: () => import('../components/teacherCourse/teacherCourseWareDetail.vue'),
                meta: {
                    title: '精选课程',
                    tabName: 'teacherCourseIndex'
                }
            },{
                path: '/qualityCourse/teacher/teacherMatingDetail',
                name: 'teacherMatingDetail',
                component: () => import('../components/teacherCourse/teacherMatingDetail.vue'),
                meta: {
                    title: '配套材料',
                    tabName: 'teacherCourseIndex'
                }
            },{
                path: '/qualityCourse/teacher/teacherTextbook',
                name: 'teacherTextbook',
                component: () => import('../components/teacherCourse/teacherTextbook.vue'),
                meta: {
                    title: '课程-教材',
                    tabName:'teacherTextbook'
                }
            },{
                path: '/qualityCourse/teacher/teacherMicroLesson',
                name: 'teacherMicroLesson',
                component: () => import('../components/teacherCourse/teacherMicroLesson.vue'),
                meta: {
                    title: '课程-微课',
                    tabName:'teacherMicroLesson'
                }
            },{
                path: '/qualityCourse/teacher/teacherAnimation',
                name: 'teacherAnimation',
                component: () => import('../components/teacherCourse/teacherAnimation.vue'),
                meta: {
                    title: '课程-动画',
                    tabName:'teacherAnimation'
                }
            },],
        },
        {
            path: '/qualityCourse/teacher/courseDetail',
            name: 'teacherCourseDetail',
            component: () => import('../components/teacherCourse/TeacherCourseDetail.vue'),
            meta: {
                title: '课程-详情页',
                // tabName:'teacherCourseIndex'
            }
        },
    ],

  },
  {
    path: '/qualityCourse',
    component: qualityCourseLayout,
    children: [{
      path: '/qualityCourse/courseDetail',
      name: 'qualityCourseDetail',
      component: () => import('../views/qualityCourse/CourseDetail.vue'),
      meta: {
        title: '课程详情'
      }
    }]
  },
    {
        path: '/qualityCourse',
        component: qualityCourseLayout,
        children: [{
            path: '/qualityCourse/classRecord',
            name: 'classRecord',
            component: () => import('../views/qualityCourse/classRecord.vue'),
            meta: {
                title: '上课记录'
            }
        },{
            path: '/qualityCourse/recordDetail',
            name: 'classRecordDetail',
            component: () => import('../views/qualityCourse/recordDetail'),
            meta: {
                title: '上课记录详情'
            }
        }]
    },
    {
        path: '/qualityCourse',
        component: qualityCourseLayout,
        children: [{
            path: '/qualityCourse/educational',
            name: 'qualityEducational',
            component: () => import('../views/qualityCourse/educational.vue'),
            meta: {
                title: '教务管理'
            },
        }]
    },
  {
    path: '/home',
    component: homeLayout,
    children: [{
      path: '/home',
      name: 'home',
      component: () => import('../views/qualityCourse/Home.vue'),
      meta: {
        title: '云平台'
      }
    },
    {
        path: '/qualityCourse/integrationCourse',
        name: 'qualityCourseIntegrationCourse',
        component: () => import('../components/qualityCourse/integrationCourse.vue'),
        meta: {
            title: '一体化课程'
        }
    },
    {
        path: '/qualityCourse/home/PublicClass',
        name: 'qualityCourseHomePublicClass',
        component: () => import('../components/qualityCourse/PublicClass.vue'),
        meta: {
            title: '公开课'
        }
    },
    {
        path: '/qualityCourse/home/PublicClassDetail',
        name: 'qualityCourseHomePublicClassDetail',
        component: () => import('../components/qualityCourse/PublicClassDetail.vue'),
        meta: {
            title: '公开课详情'
        }
    },
    {
        path: '/qualityCourse/contactUs',
        name: 'qualityCourseContactUs',
        component: () => import('../components/qualityCourse/ContactUs'),
        meta: {
            title: '联系我们'
        }
    },
    {
        path: '/qualityCourse/newsCenter',
        name: 'qualityCourseNewsCenter',
        component: () => import('../components/qualityCourse/NewsCenter.vue'),
        meta: {
            title: '新闻中心'
        }
    },
    {
        path: '/qualityCourse/newsCenterDetail',
        name: 'qualityCourseNewsCenterDetail',
        component: () => import('../components/qualityCourse/NewsCenterDetail'),
        meta: {
            title: '新闻详情'
        }
    },
    //这四个新闻详情页到时候要删掉
    {
        path: '/qualityCourse/NewsDetail',
        name: 'qualityCourseNewsDetail',
        component: () => import('../components/qualityCourse/NewsDetail.vue'),
        meta: {
            title: '新闻中心'
        }
    },
    {
        path: '/qualityCourse/NewsDetailOne',
        name: 'qualityCourseNewsDetailOne',
        component: () => import('../components/qualityCourse/NewsDetailOne.vue'),
        meta: {
            title: '新闻中心'
        }
    },
    {
        path: '/qualityCourse/NewsDetailTow',
        name: 'qualityCourseNewsDetailTow',
        component: () => import('../components/qualityCourse/NewsDetailTow.vue'),
        meta: {
            title: '新闻中心'
        }
    },
    {
        path: '/qualityCourse/NewsDetailThree',
        name: 'qualityCourseNewsDetailThree',
        component: () => import('../components/qualityCourse/NewsDetailThree.vue'),
        meta: {
            title: '新闻中心'
        }
    }]
  },
  {
    path: '/login',
    component: loginLayout,
    children: [{
      path: '/user/login',
      name: 'studentLogin',
      component: () => import('../views/qualityCourse/Login.vue'),
      meta: {
        title: '云平台--学生登录'
      }
    }]
  },{
    path: '/teacherLogin',
    component: loginLayout,
    children: [{
      path: '/user/teacherLogin',
      name: 'teacherLogin',
      component: () => import('../views/qualityCourse/teacherLogin.vue'),
      meta: {
        title: '云平台--教师登录'
      }
    }]
  },
  {
    path: '/myclass',
    component: layoutContent,
    children: [
      {
        path: '/',
        name: 'myClass',
        component: () => import('../views/student/MyClassIndex'),
        meta: {
          title: '我的班级主页'
        }
      },
      {
        path: '/myclass/detail',
        name: 'myClass',
        component: () => import('../views/student/Myclass'),
        meta: {
          title: '我的班级'
        }
      },
      {
        path: '/teacherClass/detail',
        name: 'teacherClassDetail',
        component: () => import('../views/student/TeacherClass'),
        meta: {
          title: '我的班级'
        }
      },
      {
        path: '/teacherClass/student/detail',
        name: 'teacherViewStudentDetail',
        component: () => import('../views/student/StudentDetail'),
        meta: {
          title: '查看学生'
        }
      },
      {
        path: '/teacherClass/practicalList',
        name: 'myClassPracticalList',
        component: () => import('../views/student/practicalList.vue'),
        meta: {
            title: '实训列表'
        }
     },
      {
        path: '/teacherClass/practicalDetail',
        name: 'myClassPracticalDetail',
        component: () => import('../views/student/practicalDetail.vue'),
        meta: {
            title: '实训详情'
        }
     },
      {
        path: '/teacherClass/discussList',
        name: 'myClassDiscussList',
        component: () => import('../views/student/discussList.vue'),
        meta: {
            title: '讨论列表'
        }
      },
      {
        path: '/teacherClass/discussDetail',
        name: 'myClassDiscussDetail',
        component: () => import('../views/student/DiscussDetail'),
        meta: {
            title: '讨论详情'
        }
      },
      {
        path: '/teacherClass/quizList',
        name: 'myClassQuizList',
        component: () => import('../views/student/QuizList.vue'),
        meta: {
            title: '教师小测列表'
        }
      },
      {
        path: '/teacherClass/studentQuizList',
        name: 'myClassStudentQuizList',
        component: () => import('../views/student/StudentQuizList.vue'),
        meta: {
            title: '学生小测列表'
        }
      },
      {
        path: '/teacherClass/quizApproved',
        name: 'myClassQuizApproved',
        component: () => import('../views/student/QuizApproved.vue'),
        meta: {
            title: '已批阅'
        }
      },
      {
        path: '/teacherClass/quizUnApproved',
        name: 'myClassQuizUnApproved',
        component: () => import('../views/student/QuizUnApproved.vue'),
        meta: {
            title: '未批阅'
        }
      },
      {
        path: '/teacherClass/quizUnCommitted',
        name: 'myClassQuizUnCommitted',
        component: () => import('../views/student/QuizUnCommitted'),
        meta: {
            title: '未提交'
        }
      },
      {
        path: '/teacherClass/approvedDetail',
        name: 'myClassApprovedDetail',
        component: () => import('../views/student/ApprovedDetail'),
        meta: {
            title: '问答题未批阅详情'
        }
      },
      {
        path: '/teacherClass/allApprovedDetail',
        name: 'myClassAllApprovedDetail',
        component: () => import('../views/student/AllApprovedDetail'),
        meta: {
            title: '批阅详情'
        }
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
